



import { Component, Vue } from 'vue-property-decorator';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    StepNavi,
  },
})
export default class LoginForgetPasswordComplete extends Vue {
  private stepNavi: StepNaviData[] = [
    {
      title: '会員情報入力',
    },
    {
      title: '再設定メール送信',
    },
  ];
}
